<template>
  <popup :width="width" :closePopupEvent="closePopupCB">
    <div class="vlt-container">
      <div class="profile-img">
        <span class="profile-tooltip">
          <img v-if="picture" :src="picture" alt="vlt-prof" class="profile-img-responsive" />
          <img
            v-else
            :src="defaultPicture"
            alt="vlt-prof"
            class="profile-img-responsive"
            loading="lazy"
          />
        </span>
      </div>
      <div class="display-document-picture">
        <div class="uploaded-image">
          <input
            id="file"
            type="file"
            accept=".png, .jpg"
            @change="fileUpload($event)"
            class="custom-input"
          />
          <label id="upload" for="file" class="upload-label">Upload photo</label>
        </div>
      </div>
      <div class="prof-details">
        <p>Name</p>
        <input
          class="prof-input"
          :class="isBackgroundName ? 'prof-input-focused':'prof-input-normal'"
          type="text"
          aria-label="Name"
          v-model="userName"
          placeholder="Name"
          maxlength="128"
          tabindex="1"
          @focusin="backgroundColor('isBackgroundName', true)"
          @focusout="backgroundColor('isBackgroundName', false)"
        />
        <p>Email</p>
        <input
          class="prof-input prof-input-normal"
          type="email"
          aria-label="email"
          v-model="userEmail"
          ref="userEmail"
          maxlength="128"
          tabindex="1"
          disabled
        />
         <p>Mobile No.</p>
        <input
          class="prof-input prof-input-normal"
          type="text"
          aria-label="mobile no"
          v-model="mobileNo"
          maxlength="16"
          tabindex="1"
        />
        <p>DOB</p>
        <div
          class="vlt-input-group input-ico"
          :class="isBackgroundDob ? 'prof-input-focused':'prof-input-normal'"
          @focusin="backgroundColor('isBackgroundDob', true)"
          @focusout="backgroundColor('isBackgroundDob', false)"
        >
          <VueDatepicker
            v-model="userDob"
            :first-day-of-week="1"
            :editable="false"
            lang="en"
            width="100%"
            format="DD-MM-YYYY"
            placeholder
            :not-after="new Date()"
            id="inp-dob"
          ></VueDatepicker>
        </div>
        <p>Gender</p>
        <div class="vlt-sub">
          <div class="vlt-radio inline" v-for="(genderData, index) in genderList" :key="index">
            <input
              :id="genderData"
              name="radio-gender"
              type="radio"
              v-model="gender"
              :value="genderData"
              class="radio-input"
            />
            <label :for="genderData" class="radio-label">{{ genderData }}</label>
          </div>
        </div>
        <p class="login-error-message">{{ errorMessage }}</p>
        <app-loader v-show="showLoading"></app-loader>
        <div class="save-btn">
          <button type="button" class="btn-login" aria-label="Save" @click="actSave">SAVE</button>
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import { EventBus } from "@/main";
import { mapActions, mapGetters, mapMutations } from "vuex";
import appMixins from "@/mixins/appMixins";
import { formatDateString } from '@/mixins/dateSupport';

// import VueDatepicker from "vue2-datepicker";

export default {
  data() {
    return {
      width: "35%",
      userEmail: undefined,
      genderList: ["Male", "Female", "Others"],
      gender: "Male",
      userName: undefined,
      userDob: undefined,
      mobileNo:null,
      defaultPicture: require("@/assets/icons/user-default.svg"),
      calendarIco: require("@/assets/icons/calendar.svg"),
      picture: undefined,
      isBackgroundName: false,
      isBackgroundEmail: false,
      isBackgroundDob: false,
      uploadedFileObj: "",
      errorMessage: "",
      pictureApi: "",
      showLoading: false,
      flagAutologin:true
    };
  },
  props: ["closePopupCB", "editData"],
  methods: {
    ...mapActions(["actUploadImage", "actProfileUpdate"]),
    backgroundColor(variable, state) {
      this[`${variable}`] = state;
    },
    fileUpload(event) {
      this.uploadedFileObj = event.target.files[0];
      this.showLoading = true;
      this.actUploadImage(this.uploadedFileObj).then(
        response => {
          this.showLoading = false;
          if (response.error) {
            this.errorMessage = response.reason;
          } else {
            this.errorMessage = "";
            this.pictureApi = response.success;

            var _URL = window.URL || window.webkitURL;
            this.picture = _URL.createObjectURL(this.uploadedFileObj);
          }
        },
        error => {
          this.showLoading = false;
          console.log("error");
          if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.fileUpload(event));
              this.flagAutologin = false;
              return;
            }
          }
        }
      );
    },
    verifyNumber(number) {
      let regEx = /^[0-9]{0,16}$/;
      return regEx.test(number);
    },
    actSave() {
      let param = {};
      param.subscriberid = this.editData.subscriberid;

      if (this.userName && this.userName != this.editData.subscribername) {
        param.subscribername = this.userName;
      }

      if (this.userDob && this.userDob != this.editData.dob) {
        let dobCopy = new Date( formatDateString( this.userDob ) );
        let dobTemp = "";
        // Internet Explorer 6-11
        var isIE = /*@cc_on!@*/ false || !!document.documentMode;

        // Edge 20+
        var isEdge = !isIE && !!window.StyleMedia;
        if (isEdge) {
          if (this.userDob !== dobCopy) {
            dobCopy.setDate(dobCopy.getDate() + 1);
            param.dob = new Date( formatDateString( `${dobCopy}` ) ).toISOString().slice(0, 10);
          }
        } else {
          param.dob = new Date( formatDateString( `${dobCopy}EDT` ) ).toISOString().slice(0, 10);
        }
      }
      if (this.gender && this.gender != this.editData.gender) {
        if (this.gender === "Others") {
          param.gender = "Other";
        } else {
          param.gender = this.gender;
        }
      }
      if (this.pictureApi && this.pictureApi != this.editData.picture) {
        param.picture = this.pictureApi;
      }
      if(this.mobileNo && this.mobileNo != this.editData.contactno){
        if(this.verifyNumber(this.mobileNo)){
             param.contactno = this.mobileNo;
          }
        else return this.errorMessage='Mobile Number should be in range 0-16 digits.'  
      }
      this.showLoading = true;
      this.actProfileUpdate(param)
        .then(response => {
          this.showLoading = false;
          if (response.error) {
            this.errorMessage = response.reason;
          } else {
            this.errorMessage = "";
            this.closePopupCB();
          }
        })
        .catch(error => {
          this.showLoading = false;
          console.log(error);
          if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.actSave);
              this.flagAutologin = false;
              return;
            }
          }
        });
    }
  },
  created() {
    if (this.editData) {
      if (this.editData.picture) {
        this.picture = this.editData.picture;
      }
      if (this.editData.dob) {
        this.userDob = this.editData.dob;
      }
      if (this.editData.gender) {
        this.gender =
          this.editData.gender.charAt(0).toUpperCase() +
          this.editData.gender.slice(1).toLowerCase();

          if(this.gender === "Other"){
            this.gender = "Others"
          }
      }
      this.userName = this.editData.subscribername;
      this.userEmail = this.editData.email;
      this.mobileNo=this.editData.contactno;
    }
  },
  components: {
    popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue"),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      ),
    VueDatepicker: () =>
      import(/* webpackChunkName: "date-picker" */ "vue2-datepicker")
  },
  mixins: [appMixins]
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_hovereffects.scss";

.vlt-container {
  font-family: $font-family;
  font-weight: $font-weight-regular;
  .prof-input{
      @include inputFocus;
  } 
  .display-document-picture {
    text-align: center;
    .upload-label{
      font-family:$font-family;
      font-size:1rem;
      font-weight: $font-weight-regular;
      cursor:pointer;
    }
  }
  .profile-img {
    padding: 5% 31%;
    text-align: center;
    padding-bottom: 3%;
    .profile-img-responsive {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      vertical-align: middle;
      border: solid 1px $theme-primary;
    }
  }

  #file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    // position: absolute;
    z-index: -1;
  }
  .upload-title {
    text-align: center;
  }
  .prof-details {
    padding: 0 15%;
    .vlt-sub {
      display: flex;
      flex-flow: row wrap;
      margin-top: 3%;
    }
    .prof-input {
      border: 1px solid $clr-bg-gray-light-4;
      border-radius: 2px;
      width: 100%;
      height: 40px;
      font-size:1rem;
      padding: 3%;
      margin-top: 2%;
      color: $font-clr-white;
      caret-color: $font-clr-white;
    }
    .prof-input-normal {
      background: $clr-bg-gray-light-3 0% 0% no-repeat padding-box;
    }
    .prof-input-focused {
      background: $clr-bg-gray-dark-1 0% 0% no-repeat padding-box;
    }
    p {
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 1rem;
      margin-top: 5%;
    }

    .vlt-radio {
      position: relative;
      width: 40%;
      &.inline {
        // display: inline-block;
        // margin-right: 10px;
        flex: 1;
        display: flex;
        flex-flow: row nowrap;
        padding:5px 2px;
      }
      .radio-label{
        font-family: $font-family;
        font-weight: $font-weight-regular;
        font-size:1rem;
        cursor: pointer;
      }
      .radio-input {
        opacity: 0;
        position: absolute;
        &:checked {
          + label::before {
            border: 2px solid $font-clr-white;
          }
          + label::after {
            width: 10px;
            height: 10px;
            left: 4px;
            top: 4px;
            border-radius: 50%;
            background-color: $font-clr-white;
          }
          + .radio-label {
            opacity: 1;
            font-weight: $font-weight-semibold;
            color: $font-clr-white;
          }
        }
      }
      label {
        position: relative;
        padding-left: 25px;
        font-weight: 300;
        &::before,
        &::after {
          position: absolute;
          content: "";
        }
        &::before {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          left: 0;
          color: $font-clr-white-1;
          top: 0;
          border: 2px solid $font-clr-white-1;
        }
      }
    }
    .save-btn {
      margin: 10% 0%;
    }
  }
}

.profile-tooltip {
  position: relative;
  &:hover {
    &:after {
      content: "Profile photo resolution should be 256*256 and max size allowed is 100KB";
      display: inline-block;
      position: absolute;
      background-color: black;
      padding: 5px;
      color: white;
      top: 68px;
      font-size: 10px;
      border-radius: 5px;
      left: 50%;
      width: 300%;
      transform: translate(-50%, 0);
    }
    &:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid black;
      position: absolute;
      left: 50%;
      top: 62px;
      transform: translate(-50%, 0);
    }
  }
}
</style>
